import React, { useRef, useState, useEffect, useCallback } from "react";
import { MapContainer, TileLayer, Marker, Circle, useMap, ScaleControl, useMapEvents } from "react-leaflet";
import { db } from "./db";
import Dexie from 'dexie';
import { useLiveQuery } from "dexie-react-hooks";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import { viewIcon } from "./constants";
import { Link } from 'react-router-dom';
import { Crosshair, InfoCircle, Flag } from 'react-bootstrap-icons';
import Toast from 'react-bootstrap/Toast';
import 'leaflet-rotatedmarker'; // needed to rotate marker
import { sendLocalCapturedHills } from './helpers'
import { Button, Modal } from "react-bootstrap"
import { useWakeLock } from 'react-screen-wake-lock';

function Map() {

    const mapRef = useRef(null);
    const [currentLocation, setCurrentLocation] = useState(null);
    const [currentOrientation, setCurrentOrientation] = useState(0);
    const [locationStatus, setLocationStatus] = useState('unknown')
    const [visibleHillsArea, setVisibleHillsArea] = useState({ lon_min: 18, lon_max: 18 }); // area of displayed hills
    const [selectedHill, setSelectedHill] = useState(null); // holds selected hill id
    // inite as true if there are no downloaded capturable hills
    const [showNoHillsModal, setShowNoHillsModal] = useState(() => {
        const storedRegions = JSON.parse(localStorage.getItem('downloaded_regions'));
        return storedRegions === null || storedRegions.length === 0;
    });
    const [showToast, setShowToast] = useState(false);
    const [toastMsg, setToastMsg] = useState({ 'status': 'Success', 'message': 'Hill captured...' });
    const currentLocationRef = useRef(null);
    const trackedLocations = useRef(null);
    const currentMapViewCenter = useRef({ 'lat': 45, 'lon': 18 });
    // get last location or default
    // const initMapLocation = useRef(JSON.parse(localStorage.getItem('locations'))?.slice(-1)[0] || { 'lat': 45, 'lon': 18 });
    const initMapLocation = useRef(JSON.parse(localStorage.getItem('lastMapCenter')) || { 'lat': 45, 'lon': 18 });
    const { isSupported, released, request, release } = useWakeLock({
        // onRequest: () => alert('Screen Wake Lock: requested!'),
        // onError: () => alert('An error happened \uD83D\uDCA5'),
        // onRelease: () => alert('Screen Wake Lock: released!'),
    });



    const HILL_RADIUS = 125; // m
    const ACC_NEEDED = 25; //needed accuracy to capture hill


    const hills = useLiveQuery(
        async () => {
            const hills = await db.hills
                .where('longitude')
                .between(visibleHillsArea.lon_min, visibleHillsArea.lon_max)
                .toArray();

            // Return result
            return hills;
        },
        // specify vars that affect query:
        [visibleHillsArea]
    );
    // console.log('hills', hills)


    useEffect(() => {
        // save last mapcenter to local storage when component unmounts
        return () => {
            // console.log("unmounting map component, writing mapcenter", currentMapViewCenter.current);
            localStorage.setItem('lastMapCenter', JSON.stringify(currentMapViewCenter.current));
        };
    }, []);


    useEffect(() => {
        let watchId = null
        // check for geolocation support in browser
        if ('geolocation' in navigator) {
            watchId = navigator.geolocation.watchPosition((position) => {
                // console.log(`User's updated location: Lat ${position.coords.latitude}, Lng ${position.coords.longitude}, acc: ${position.coords.accuracy}`);
                setCurrentLocation({
                    lat: position.coords.latitude,
                    lon: position.coords.longitude,
                    accuracy: position.coords.accuracy
                })
                setLocationStatus('accessed')
            }, (error) => {
                switch (error.code) {
                    case error.PERMISSION_DENIED:
                        setLocationStatus('denied')
                        break
                    case error.POSITION_UNAVAILABLE:
                        setLocationStatus('unknown')
                        break
                    case error.TIMEOUT:
                        setLocationStatus('error')
                        break
                    default:
                        setLocationStatus('error')
                        break
                }
            }, { enableHighAccuracy: true, timeout: 5000 })
            return () => {
                if (watchId) {
                    navigator.geolocation.clearWatch(watchId)
                }
            }
        }
    }, [])

    // initialize user tracking object
    useEffect(() => {
        const initialElements = JSON.parse(localStorage.getItem('locations')) || [];
        trackedLocations.current = initialElements

        // console.log('DeviceOrientationEvent is supported probably: ', typeof DeviceOrientationEvent.requestPermission === 'function')
        // let a = typeof DeviceOrientationEvent.requestPermission === 'function'
        // alert('DeviceOrientationEvent is supported probably: ' + a)
    }, [])


    // Update the ref location when user's location changes, so we can use it in timer
    useEffect(() => {
        if (currentLocation !== null) {
            currentLocationRef.current = currentLocation;
        }
    }, [currentLocation]);


    // initialize interval to track user location approx. every minute - check every 10s
    useEffect(() => {
        const interval = setInterval(() => {
            if (currentLocationRef.current) {
                // Add a new element to the end of the array
                // current utc time
                const currentDateTime = new Date();
                const formattedDateTime = currentDateTime.toISOString().slice(0, 19).replace('T', ' ');
                const lastElement = trackedLocations.current.slice(-1)[0]
                const lastTimestampMinute = lastElement?.timestamp.slice(0, 16)
                const currentTimestampMinute = formattedDateTime.slice(0, 16)
                if (lastTimestampMinute !== currentTimestampMinute) {
                    var copiedValue = { ...currentLocationRef.current };
                    copiedValue['timestamp'] = formattedDateTime
                    trackedLocations.current.push(copiedValue)
                    // Remove the first element if the array length exceeds 10
                    if (trackedLocations.current.length > 10) {
                        trackedLocations.current.splice(0, 1)
                    }
                    // Save the updated array to local storage
                    localStorage.setItem('locations', JSON.stringify(trackedLocations.current));
                }
            }
        }, 10000); // 1 minute interval

        return () => clearInterval(interval);
    }, []);


    const handleDeviceOrientation = useCallback(
        (e) => {
            // do something with `e.alpha`, `e.beta`, and `e.gamma`
            // console.log('rotation', e.alpha)
            // console.log(e.beta)
            // console.log(e.gamma)
            // console.log(e.absolute)
            var compass = -(e.alpha + e.beta * e.gamma / 90);
            compass -= Math.floor(compass / 360) * 360; // Wrap to range [0,360]
            compass = e.webkitCompassHeading || compass
            // console.log('heading', compass)
            setCurrentOrientation(compass)
        },
        []
    );

    useEffect(() => {
        // window.addEventListener(
        //     'deviceorientationabsolute',
        //     handleDeviceOrientation, true
        // );
        let listenerName = null;

        if (typeof DeviceOrientationEvent.requestPermission === 'function') {
            console.log('this is ios')
            listenerName = 'deviceorientation'
            DeviceOrientationEvent.requestPermission()
                .then((response) => {
                    if (response === "granted") {
                        window.addEventListener(listenerName, handleDeviceOrientation, true);
                    } else {
                        alert(" Device orientation has to be allowed to use this app.");
                    }
                })
                .catch(() => alert("not supported"));
        } else {
            console.log('this is android')
            listenerName = 'deviceorientationabsolute'
            window.addEventListener(listenerName, handleDeviceOrientation, true);
        }

        return () =>
            window.removeEventListener(
                listenerName,
                handleDeviceOrientation, true
            );
    }, [handleDeviceOrientation]);


    // screen wake lock
    useEffect(() => {
        // Activate the wake lock when the component mounts
        request();
        // Clean up: Deactivate the wake lock when the component unmounts
        return () => {
            release();
        };
    }, []);


    function checkLocationPermission() {
        if (navigator.geolocation) {
            navigator.permissions.query({ name: 'geolocation' }).then(permissionStatus => {
                // alert('geolocation status: ' + permissionStatus.state);
                console.log('permissionStatus status ', permissionStatus.state)
                if (permissionStatus.state === 'denied') {
                    alert('You have denied location access for this application. Please allow it in the web browser settings, otherwise you will not be able to use this applicaiton properly.');
                    //   window.location.href = "app-settings:location";
                }
            });
        } else {
            alert('Geolocation is not supported in your browser.');
        }
    }

    // function startCompass() {
    //     if (false) {
    //       DeviceOrientationEvent.requestPermission()
    //         .then((response) => {
    //           if (response === "granted") {
    //             window.addEventListener("deviceorientation", handler, true);
    //           } else {
    //             alert("has to be allowed!");
    //           }
    //         })
    //         .catch(() => alert("not supported"));
    //     } else {
    //       window.addEventListener("deviceorientationabsolute", handler, true);
    //     }
    //   }

    //   function handler(e) {
    //     const compass = e.alpha
    //     console.log('compassss', compass)
    //     console.log('compassss2', e.webkitCompassHeading)
    //   }

    //   startCompass()


    // handle selecting and reselecting hills
    const handleCircleClick = (hill_id, index) => {
        if (hill_id === selectedHill) {
            setSelectedHill(null);
        } else {
            setSelectedHill(hill_id);
        }
        // console.log('clicked circle', hill_id)
        // console.log('clicked circle', hills[index])
    };


    function locationsToString(data, field) {
        const array = data.map(entry => entry[field]);
        return array.join(',');
    }


    const handleCaptureClick = async () => {
        checkLocationPermission()

        if (!currentLocation) {
            setToastMsg({ 'status': 'Error', 'message': 'Your location is unknown.' })
            setShowToast(!showToast)
            return
        }
        // caluclate distance to target hill
        let distanceInMeters = null
        let selectedHillData = null
        try {
            selectedHillData = await db.hills.get({ 'hill_id': selectedHill })
            // calc distance using library
            const markerFrom = L.circleMarker([selectedHillData.latitude, selectedHillData.longitude]);
            const markerTo = L.circleMarker([currentLocation.lat, currentLocation.lon]);
            const from = markerFrom.getLatLng();
            const to = markerTo.getLatLng();
            distanceInMeters = from.distanceTo(to);
            console.log('distanceInMeters', distanceInMeters)
        } catch (error) {
            console.log(`Failed to capture. Error: ${error}`);
        }

        // check dif already captured
        if (selectedHillData.captured) {
            setToastMsg({ 'status': 'Error', 'message': 'This hill is already captured.' })
            setShowToast(!showToast)
            return
        }
        // check distance
        if (distanceInMeters > HILL_RADIUS) {
            setToastMsg({ 'status': 'Error', 'message': 'You are too far from this hill. Come closer!' })
            setShowToast(!showToast)
            return
        }
        // check accuracy
        if (currentLocation.accuracy > ACC_NEEDED) {
            setToastMsg({ 'status': 'Error', 'message': 'Your GPS accuracy is too poor. It is not possible to capture the hill with such poor GPS accuracy.' })
            console.log('GPS accuracy', currentLocation.accuracy)
            setShowToast(!showToast)
            return
        }


        // add captured hill to temporary storage for offline usage
        const currentDateTime = new Date();
        const formattedDateTime = currentDateTime.toISOString().slice(0, 19).replace('T', ' ');
        try {
            await db.captures.add({
                hill_id: selectedHill,
                cap_date: formattedDateTime,
                latitudes: locationsToString(trackedLocations.current, 'lat'),
                longitudes: locationsToString(trackedLocations.current, 'lon'),
                timestamps: locationsToString(trackedLocations.current, 'timestamp'),
                android_id: null,
                device_model: `${window.innerWidth}-${window.innerHeight}-${window.devicePixelRatio}`,
            });
            setToastMsg({ 'status': 'Success', 'message': 'The hill captured successfully!' })
            setShowToast(!showToast)
        } catch (error) {
            console.log(`Failed to capture. Error: ${error}`);
            setToastMsg({ 'status': 'Error', 'message': `Failed to capture. Error: ${error}` })
            setShowToast(!showToast)
        }

        // change flag in hills table
        db.transaction("rw", db.hills, async () => {
            await db.hills.where("hill_id").equals(selectedHill).modify({ captured: 1 });
            setSelectedHill(null);
        }).catch(Dexie.ModifyError, error => {
            // ModifyError did occur
            console.error(error.failures.length + " items failed to modify");
            setToastMsg({ 'status': 'Error', 'message': 'Items failed to modify' })
            setShowToast(!showToast)
        }).catch(error => {
            console.error("Generic error: " + error);
            setToastMsg({ 'status': 'Error', 'message': "Unknown error: " + error })
            setShowToast(!showToast)
        });

        // send hill to the server
        sendLocalCapturedHills()

    };

    const handleShowDialogNoHills = () => {
        setShowNoHillsModal(!showNoHillsModal);
    };


    // button to center map to current user's locatin. It must be own component in MAp container
    function CenterButton() {
        const map = useMap()
        const handleCenterClick = (map) => {
            checkLocationPermission()
            if (currentLocation) {
                console.log('currentLocation', currentLocation)
                map.flyTo([currentLocation.lat, currentLocation.lon])
            }
        };
        return (
            <>
                <Button variant="primary" className="floating-button-right rounded-circle p-0" onClick={() => handleCenterClick(map)} style={{ zIndex: '1000', width: '42px', height: '42px' }}><Crosshair size={25} /></Button>
            </>
        )
    }



    // marger and accuracy of user's location
    function UserLocationMarker() {
        return (<>
            <Marker position={[currentLocation.lat, currentLocation.lon]} icon={viewIcon} rotationAngle={currentOrientation} rotationOrigin='center'>
            </Marker>
            <Circle
                center={[currentLocation.lat, currentLocation.lon]}
                radius={Math.min(currentLocation.accuracy, 500)}
                pathOptions={{ color: 'brown' }}
            >
            </Circle>
        </>
        );
    }




    // this component detects dragging and returns map centercoordinates, so smaller number of hills can be displayed
    function MoveDetector() {
        const map = useMapEvents({
            moveend: (e) => {
                // console.log("mapCenter", e.target.getCenter());
                // console.log("map bounds", e.target.getBounds());
                // console.log("map e.target", e.target);

                // setVisibleHillsArea({lon_min: Math.floor(e.target.getBounds()._southWest.lng), lon_max: Math.ceil(e.target.getBounds()._northEast.lng)})
                if (e.target._zoom >= 10) {
                    setVisibleHillsArea({ lon_min: e.target.getBounds()._southWest.lng, lon_max: e.target.getBounds()._northEast.lng })
                    currentMapViewCenter.current = { 'lat': e.target.getCenter().lat, 'lon': e.target.getCenter().lng }
                } else {
                    setVisibleHillsArea({ lon_min: 0, lon_max: 0 }) // do not show any hills
                }
            }
        });
        return null;
    }


    return (
        <>
            <div className='navbarmargin'></div>

            {/* // display height without navbat */}
            <div style={{ height: 'calc(100vh - 56px)' }}>
                {/* {mapRef ? <DisplayPosition map={mapRef} /> : null} */}
                {/* <h1>This is map</h1> */}


                {/* // Make sure you set the height and width of the map container otherwise the map won't show */}
                {/* <MapContainer center={[55, 27]} zoom={13} ref={mapRef} style={{  height: "100vh", width: "100vw"}}> */}
                {/* <MapContainer center={[55, 27]} zoom={13} ref={mapRef} style={{  height: 'calc(100% - 30px)', width: "100vw"}}> */}
                <MapContainer center={[initMapLocation.current.lat, initMapLocation.current.lon]} zoom={13} ref={mapRef} style={{ height: '100%' }} zoomControl={false}>
                    {selectedHill && <Button variant="primary" className="floating-button-topright rounded-circle p-0" onClick={() => handleCaptureClick()} style={{ zIndex: '1000', width: '42px', height: '42px' }}><Flag size={25} /></Button>}
                    {selectedHill && <Link to={'/hillinfo'} state={{ 'hill_id': selectedHill }}>
                        <Button variant="primary" className="floating-button-topleft rounded-circle p-0" style={{ zIndex: '1000', width: '42px', height: '42px' }}><InfoCircle size={25} /></Button>
                    </Link>}


                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">Seznam.cz</a>'
                        // url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        url={`https://api.mapy.cz/v1/maptiles/outdoor/256/{z}/{x}/{y}?apikey=${process.env.REACT_APP_SEZNAM_API_KEY}&lang=en`}
                    />
                    {currentLocation && <UserLocationMarker />}


                    {hills?.map((hill, index) => (
                        <Circle
                            key={hill.hill_id}
                            center={[hill.latitude, hill.longitude]} // Adjust the center coordinates
                            radius={HILL_RADIUS} // Adjust the radius
                            pathOptions={{
                                color: hill.hill_id === selectedHill ? 'orange' : hill.captured ? 'blue' : hill.bounty ? 'purple' : 'red',

                            }}
                            eventHandlers={{ click: () => handleCircleClick(hill.hill_id, index) }}
                        >
                        </Circle>
                    ))}

                    <MoveDetector />

                    <ScaleControl position="bottomleft" />
                    <CenterButton />


                    <Toast className="bottom-end" style={{ zIndex: '1000', position: 'fixed', top: '50%' }}
                        show={showToast}
                        onClose={() => setShowToast(false)}
                        delay={3000} autohide
                    >
                        <Toast.Header>
                            <strong>{toastMsg.status}</strong>
                        </Toast.Header>
                        <Toast.Body>{toastMsg.message}</Toast.Body>
                    </Toast>

                </MapContainer>

                {/* // modal with info if no capturable hills are downloaded */}
                <div>
                    <Modal show={showNoHillsModal} onHide={handleShowDialogNoHills} centered>
                        <Modal.Header closeButton>
                            <Modal.Title>Missing capturable places</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            You have not downloaded capturable hills yet. Open menu and select "Download Hills" tab and download capturable places for your region.
                        </Modal.Body>
                    </Modal>
                </div>

            </div>
        </>
    );
}

export default Map;